<template>
  <a-modal
    :open="open"
    width="80%"
    :closable="false"
    centered
    :footer="null"
    @cancel="handleFinish"
    wrap-class-name="header-steps-modal postmaster-modal"
  >
    <a-layout>
      <a-layout-sider>
        <a-config-provider :theme="getSpecialTheme()">
          <a-steps direction="vertical" :current="current" :items="items">
          </a-steps>
        </a-config-provider>
      </a-layout-sider>
      <a-layout>
        <a-layout-header>
          <a-config-provider :theme="getSpecialTheme()">
            <a-button @click="handleFinish" type="text">
              <i class="fa-solid fa-xmark"></i>
            </a-button>
          </a-config-provider>
        </a-layout-header>
        <a-layout-content class="main-content">
          <template v-if="current == 0">
            <setup-1 />
          </template>
          <template v-else-if="current == 1">
            <setup-2 />
          </template>
          <template v-else-if="(current = 2)">
            <setup-3 />
          </template>
        </a-layout-content>
        <a-layout-footer>
          <a-config-provider :theme="getSpecialTheme()">
            <a-button v-if="current != 2" type="primary" @click="handleNext">
              Next
            </a-button>
            <a-button
              v-else-if="current == 2"
              type="primary"
              @click="handleFinish"
            >
              Finish
            </a-button>
            <a-button
              v-if="current != 0"
              class="mr-10"
              type="primary"
              @click="handlePrevious"
            >
              Previous
            </a-button>
          </a-config-provider>
        </a-layout-footer>
      </a-layout>
    </a-layout>
  </a-modal>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';

import Setup1 from './components/Setup1/Setup1.vue';
import Setup2 from './components/Setup2/Setup2.vue';
import Setup3 from './components/Setup3/Setup3.vue';

export default defineComponent({
  components: {
    Setup1,
    Setup2,
    Setup3,
  },
  props: ['open'],
  emits: ['close'],
  setup(props, { emit }) {
    const current = ref(0);
    const steps = [
      {
        title: 'STEP 1:',
        description: 'Reputation Setup',
      },
      {
        title: 'STEP 2:',
        description: 'Include Mail Monitor',
      },
      {
        title: 'STEP 3:',
        description: 'Add Domains',
      },
    ];
    const items = steps.map((item) => ({
      key: item.title,
      title: item.title,
      description: item.description,
    }));

    const handlePrevious = () => {
      current.value--;
    };

    const handleNext = () => {
      current.value++;
    };

    const handleFinish = () => {
      emit('close');
    };

    watch(
      () => props.open,
      (open) => {
        if (!open) return;

        current.value = 0;
      }
    );

    return {
      steps,
      items,
      current,
      handlePrevious,
      handleNext,
      handleFinish,
    };
  },
});
</script>

<style lang="less">
.postmaster-modal {
  .add-content {
    display: flex;

    input {
      margin-right: 20px;
    }
  }

  .ant-alert-info {
    background-color: #d2f0f3;
    border: none;
  }

  .ant-alert-description {
    color: black;
    display: flex;
    flex-direction: column;
    align-items: initial;
  }

  .ant-alert {
    align-self: center;
    color: white;
    border: 1px;
    border-radius: 8px;
  }

  .ant-alert-success {
    background-color: #2f858d;
    border: none;
  }

  .ant-alert-error {
    background-color: #ff6384;
    border: none;
  }

  .ant-alert-message {
    color: white;
  }

  .ant-alert-icon {
    filter: brightness(0) invert(1);
    width: 16px;
  }
}
</style>
