<template>
  <a-modal
    width="80%"
    :open="open"
    centered
    :closable="false"
    :footer="null"
    @cancel="handleClose"
    wrap-class-name="header-modal reputation-modal"
  >
    <a-layout>
      <a-layout-header>
        <div class="title">Reputation Setup</div>
        <div class="close-button">
          <a-config-provider :theme="getSpecialTheme()">
            <a-button @click="handleClose" type="text">
              <i class="fa-solid fa-xmark" />
            </a-button>
          </a-config-provider>
        </div>
      </a-layout-header>
      <a-layout-content class="main-content">
        <reputation-setup />
      </a-layout-content>
    </a-layout>
  </a-modal>
</template>

<script>
import { defineComponent } from 'vue';

import ReputationSetup from '@/views/ReputationMonitors/ReputationSetup/ReputationSetup.vue';

export default defineComponent({
  name: 'ReputationSetupModal',
  components: {
    ReputationSetup,
  },
  props: ['open'],
  emits: ['close'],
  setup(props, { emit }) {
    const handleClose = () => {
      emit('close');
    };

    return {
      handleClose,
    };
  },
});
</script>

<style lang="less" scoped>
@import './ReputationSetupModal.less';
</style>

<style lang="less">
.reputation-modal {
  .main-content {
    padding: 10px 50px 10px 50px;
    display: flex;
    flex-direction: column;
    background-color: white;
  }

  .ant-layout {
    height: calc(90vh);
  }

  .ant-table-wrapper {
    overflow-y: scroll;
  }

  .ant-layout-header {
    border-bottom: none;
  }
}
</style>
