<template>
  <a-modal
    centered
    :closable="false"
    :footer="null"
    @cancel="handleClose"
    :open="open"
    wrap-class-name="header-modal"
  >
    <a-spin size="large" :spinning="loading">
      <a-layout>
        <a-layout-header>
          <div class="title">Add extra login</div>
          <div class="close-button">
            <a-config-provider :theme="getSpecialTheme()">
              <a-button @click="handleClose" type="text">
                <i class="fa-solid fa-xmark" />
              </a-button>
            </a-config-provider>
          </div>
        </a-layout-header>
        <a-layout-content class="main-content">
          <a-row :gutter="[16]">
            <a-col span="24">
              <div class="form-group">
                <p class="form-label">Email</p>
                <a-input
                  class="w-full"
                  size="large"
                  v-model:value="inputData.username"
                />
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="[16]">
            <a-col span="24">
              <div class="form-group">
                <p class="form-label">Password</p>
                <a-input
                  class="w-full"
                  size="large"
                  v-model:value="inputData.password"
                />
              </div>
            </a-col>
          </a-row>
        </a-layout-content>
        <a-layout-footer>
          <a-config-provider :theme="getSpecialTheme()">
            <a-button type="primary" @click="handleAddExtraLogin">Add</a-button>
            <a-button @click="handleClose">Cancel</a-button>
          </a-config-provider>
        </a-layout-footer>
      </a-layout>
    </a-spin>
  </a-modal>
</template>

<script>
import { message } from 'ant-design-vue';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';

import api from '@/api';

export default defineComponent({
  name: 'NewExtraLoginModal',
  props: ['open'],
  emits: ['close'],
  setup(props, { emit }) {
    const router = useRouter();

    const inputData = ref({
      username: '',
      password: '',
    });
    const loading = ref(false);

    // watch(() => props.open);

    const handleAddExtraLogin = () => {
      loading.value = true;

      api.UserApi.addExtraLogin(
        inputData.value.username,
        inputData.value.password
      )
        .then(() => {
          router.go();
        })
        .catch((e) => {
          if (e.response && e.response.data && e.response.data.detail) {
            message.error(e.response.data.detail);
          } else {
            message.error(
              'Something went wrong. Please select a new user email for the sub account.'
            );
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const handleClose = () => {
      emit('close');
    };

    return {
      inputData,
      loading,
      handleAddExtraLogin,
      handleClose,
    };
  },
});
</script>
