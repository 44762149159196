<template>
  <date-range-picker
    ref="picker"
    opens="right"
    :date-range="dateRange"
    v-on:update:modelValue="handleChangeSearchDate"
  >
    <template v-slot:input="picker">
      <i class="fa fa-calendar" />
      &nbsp;&nbsp;
      {{ formatDateTime(picker.startDate, 'MMMM D, YYYY') }}
      &nbsp;-&nbsp;
      {{ formatDateTime(picker.endDate, 'MMMM D, YYYY') }}
    </template>
  </date-range-picker>
</template>

<script>
import { defineComponent } from 'vue';
import { useStore } from 'vuex';

import DateRangePicker from '@/components/Vue3DateRangePicker';
import { STORE_EVENT } from '@/store';

export default defineComponent({
  components: {
    DateRangePicker,
  },
  props: {
    dateRange: {
      type: [Object],
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const handleChangeSearchDate = (value) => {
      store.dispatch(STORE_EVENT.CHANGE_SEARCH_DATE_RANGE, value);
      emit('update', value);
    };

    return {
      handleChangeSearchDate,
    };
  },
});
</script>

<style>
@import './DateRangePicker.less';
</style>
