<template>
  <h4>Add custom domain</h4>

  <div class="add-domain-section">
    <a-row :gutter="[20, 20]">
      <a-col span="6">
        <div class="form-group">
          <p class="form-label">Domain IP</p>
          <a-input class="w-full" v-model:value="inputDomainOrIp" />
        </div>
      </a-col>
      <a-col span="6">
        <div class="form-group">
          <p class="form-label">Label</p>
          <a-input class="w-full" v-model:value="inputLabel" />
        </div>
      </a-col>
      <a-col span="6">
        <a-config-provider :theme="getSpecialTheme()">
          <div style="margin-top: 25px">
            <a-button type="primary" @click="handleAddDomainOrIp">Add</a-button>
          </div>
        </a-config-provider>
      </a-col>
    </a-row>
  </div>

  <br />

  <a-table :columns="columns" :dataSource="dataTable" :loading="loading">
    <template #bodyCell="{ column, record }">
      <template v-if="column.name == 'action'">
        <a-button type="link" @click="handleDeleteDomainOrIp(record)">
          <template #icon>
            <img src="@/assets/img/delete.svg" />
          </template>
        </a-button>
      </template>
    </template>
  </a-table>
</template>

<script>
import { message, Modal } from 'ant-design-vue';
import { defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import api from '@/api';
import { STORE_EVENT } from '@/store';
import { checkIPDomain } from '@/utils/util';

const columns = [
  {
    title: 'Domain IP',
    dataIndex: 'sendingServerIp',
  },
  {
    title: 'Label',
    dataIndex: 'label',
  },
  {
    title: '',
    name: 'action',
    width: 100,
    align: 'right',
  },
];

export default defineComponent({
  setup() {
    const store = useStore();
    const dataTable = ref([]);
    const inputDomainOrIp = ref('');
    const inputLabel = ref('');

    onMounted(() => {
      store.dispatch(STORE_EVENT.CHANGE_PAGE_NAME, 'Reputation');

      getAllDomainOrIp();
    });

    const getAllDomainOrIp = () => {
      store.dispatch(STORE_EVENT.START_LOADING_PAGE);

      api.ReputationApi.getAllDomainOrIp()
        .then((res) => {
          dataTable.value = res.data;
        })
        .finally(() => {
          store.dispatch(STORE_EVENT.STOP_LOADING_PAGE);
        });
    };

    const handleAddDomainOrIp = () => {
      if (!checkIPDomain(inputDomainOrIp.value)) {
        message.error('Invalid Domain or IP');
      } else {
        store.dispatch(STORE_EVENT.START_LOADING_PAGE);

        api.ReputationApi.addDomainOrIp(inputDomainOrIp.value, inputLabel.value)
          .then(() => {
            inputDomainOrIp.value = '';
            inputLabel.value = '';
            getAllDomainOrIp();
          })
          .catch((error) => {
            store.dispatch(STORE_EVENT.STOP_LOADING_PAGE);
            message.error(error.response.data.detail);
          });
      }
    };

    const handleDeleteDomainOrIp = (record) => {
      Modal.confirm({
        title: 'Delete Confirmation',
        content: 'Are you sure want to remove domain or ip address?',
        okText: 'Yes',
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
        cancelText: 'No',
        onOk() {
          return api.ReputationApi.deleteDomainOrIp(
            record.sendingServerId,
            record.sendingServerIp
          ).then(() => {
            message.success('Domain or Ip deleted successfully.');
            getAllDomainOrIp();
          });
        },
      });
    };

    return {
      columns,
      dataTable,
      inputLabel,
      inputDomainOrIp,
      handleDeleteDomainOrIp,
      handleAddDomainOrIp,
    };
  },
});
</script>

<style lang="less" scoped>
@import './ReputationSetup.less';
</style>
<!-- 
<template>
  <a-modal
    v-model:open="open"
    width="90%"
    centered
    :footer="null"
    @cancel="handleClose"
    wrap-class-name="custom-full-modal"
  >
    <div class="content-section">
      <h2 class="stats-heading">Reputation Setup</h2>
      <div class="step-container">
        <div class="md-width">
          <p>
            Enter the domain or IP address and add an identification label, then
            click "Add". To remove a domain or IP address from your monitoring
            list, click the trash can to delete a row.
          </p>
          <div class="steps-container">
            <div class="step-action">
              <a-input
                v-model:value="inputDomainOrIp"
                placeholder="Add Domain/Ip"
              />
              <a-input v-model:value="inputLabel" placeholder="Add Label" />
              <a-button
                type="primary"
                class="w-full"
                @click="handleAddDomainOrIp"
              >
                <template #icon>
                  <i class="fa fa-plus mr-5" />
                </template>
                Add
              </a-button>
            </div>
          </div>

          <a-table
            sticky
            :columns="columns"
            :dataSource="dataTable"
            :locale="emptyLocale"
            :loading="loading"
          >
            <template #bodyCell="{ column, record }">
              <template v-if="column.name == 'action'">
                <a-button type="link" @click="handleDeleteDomainOrIp(record)">
                  <template #icon>
                    <i class="fa fa-trash-o" />
                  </template>
                </a-button>
              </template>
            </template>
          </a-table>
        </div>
      </div>

      <div class="step-footer">
        <a-button type="primary" @click="handleClose"> Finish </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { message, Modal } from 'ant-design-vue';
import { defineComponent, h, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import api from '@/api';
import { STORE_EVENT } from '@/store';
import { checkIPDomain } from '@/utils/util';

const columns = [
  {
    title: 'Domain',
    dataIndex: 'sendingServerIp',
  },
  {
    title: 'Label',
    dataIndex: 'label',
  },
  {
    title: '',
    name: 'action',
    width: 100,
  },
];

const svgIcon = new URL('@/assets/img/icons/empty.svg', import.meta.url).href;

// Custom empty content with SVG from assets
const emptyLocale = {
  emptyText: h('div', { style: { textAlign: 'center' } }, [
    h('img', {
      src: svgIcon,
      alt: 'No Data',
      style: { width: '48px', height: '48px', marginBottom: '2px' },
    }),
    h('p', { style: { marginTop: '2px' } }, 'No domain or IP added'),
  ]),
};

export default defineComponent({
  setup() {
    const router = useRouter();
    const store = useStore();
    const dataTable = ref([]);
    const inputDomainOrIp = ref('');
    const inputLabel = ref('');
    const open = ref(true);
    const loading = ref(false);

    onMounted(() => {
      store.dispatch(STORE_EVENT.CHANGE_PAGE_NAME, 'Reputation');

      getAllDomainOrIp();
    });

    const getAllDomainOrIp = () => {
      store.dispatch(STORE_EVENT.START_LOADING_PAGE);
      loading.value = true;

      api.ReputationApi.getAllDomainOrIp()
        .then((res) => {
          dataTable.value = res.data;
        })
        .finally(() => {
          store.dispatch(STORE_EVENT.STOP_LOADING_PAGE);
          loading.value = false;
        });
    };

    const handleAddDomainOrIp = () => {
      if (!checkIPDomain(inputDomainOrIp.value)) {
        message.error('Invalid Domain or IP');
      } else {
        store.dispatch(STORE_EVENT.START_LOADING_PAGE);

        api.ReputationApi.addDomainOrIp(inputDomainOrIp.value, inputLabel.value)
          .then(() => {
            inputDomainOrIp.value = '';
            inputLabel.value = '';
            getAllDomainOrIp();
          })
          .catch((error) => {
            store.dispatch(STORE_EVENT.STOP_LOADING_PAGE);
            message.error(error.response.data.detail);
          });
      }
    };

    const handleDeleteDomainOrIp = (record) => {
      Modal.confirm({
        title: 'Delete Confirmation',
        content: 'Are you sure want to remove domain or ip address?',
        okText: 'Yes',
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
        cancelText: 'No',
        onOk() {
          return api.ReputationApi.deleteDomainOrIp(
            record.sendingServerId,
            record.sendingServerIp
          ).then(() => {
            message.success('Domain or Ip deleted successfully.');
            getAllDomainOrIp();
          });
        },
      });
    };

    const handleClose = () => {
      router.back();
    };

    return {
      columns,
      emptyLocale,
      dataTable,
      inputLabel,
      inputDomainOrIp,
      open,
      handleDeleteDomainOrIp,
      handleAddDomainOrIp,
      handleClose,
    };
  },
});
</script>

<style lang="less">
@import './ReputationSetup.less';
</style> -->
