<template>
  <span> Include Mail Monitor into your Google Postmaster Tools. </span>

  <br />

  <a-alert show-icon>
    <template #icon>
      <img style="filter: brightness(1)" src="@/assets/img/bulb-icon.png" />
    </template>
    <template #description>
      To share access to your domain with Mail Monitor:
      <br />
      <br />
      <ul>
        <li>
          On your computer, sign in to
          <a href="https://postmaster.google.com/"> Gmail Postmaster Tools </a>
        </li>
        <li>Point your cursor on one of your verified domains</li>
        <li>To the right of the domain, click the "More⋮" (3 vertical dots)</li>
        <li>Click "Manage Users"</li>
        <li>At bottom-right, click "Add +"</li>
        <li>
          In the pop-up, enter the MailMonitor GPT alias
          '<strong>postmaster-collector@postmaster-20201-1606153296792.iam.gserviceaccount.com</strong>'
          with "read access" on your domain
        </li>
      </ul>
      <br />
      After completing this guide, it may take a few hours to link your
      Postmaster Tools account and start collecting data.
    </template>
  </a-alert>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Setup-2',
});
</script>
