<template>
  <span> Setup your domains in our Reputation Monitor. </span>
  <reputation-setup />
</template>

<script>
import { defineComponent } from 'vue';

import ReputationSetup from '@/views/ReputationMonitors/ReputationSetup/ReputationSetup.vue';

export default defineComponent({
  name: 'Setup-1',
  components: {
    ReputationSetup,
  },
});
</script>
