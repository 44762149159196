<template>
  <a-modal
    :open="open"
    width="80%"
    :closable="false"
    centered
    :footer="null"
    @cancel="handleFinish"
    wrap-class-name="header-steps-modal record-generator"
  >
    <a-spin size="large" :spinning="loading">
      <a-layout>
        <a-layout-sider>
          <a-config-provider :theme="getSpecialTheme()">
            <a-steps direction="vertical" :current="current" :items="items">
            </a-steps>
          </a-config-provider>
        </a-layout-sider>
        <a-layout>
          <a-layout-header>
            <a-config-provider :theme="getSpecialTheme()">
              <a-button @click="handleFinish" type="text">
                <i class="fa-solid fa-xmark" />
              </a-button>
            </a-config-provider>
          </a-layout-header>
          <a-layout-content class="main-content">
            <!-- Domain -->
            <template v-if="current == 0">
              <h4>What domain would you like to create a record for?</h4>
              <div class="form-group">
                <p class="form-label">Enter your domain URL</p>
                <a-input
                  style="width: 400px"
                  v-model:value="dmarcData.domain"
                />
              </div>
            </template>
            <!-- DMARC Policy -->
            <template v-else-if="current == 1">
              <h4>What type of DMARC policy do you want?</h4>
              <p>
                DMARC allows you to apply a "policy" to email that appears
                unaligned with your domain.
              </p>
              <br />
              <div class="form-group">
                <p class="form-label">
                  What do you want to do with these mails that fails the DMARC
                  check?
                </p>
                <a-config-provider :theme="getSpecialTheme()">
                  <a-radio-group v-model:value="dmarcData.policy" size="large">
                    <a-radio value="none" :style="radioStyle"
                      >None (recommended)</a-radio
                    >
                    <a-radio value="quarantine" :style="radioStyle"
                      >Quarantine</a-radio
                    >
                    <a-radio value="reject" :style="radioStyle">Reject</a-radio>
                  </a-radio-group>
                </a-config-provider>
              </div>
            </template>
            <!-- Identifider Alignment -->
            <template v-else-if="current == 2">
              <h4>Relaxed or Strict mechanisms?</h4>
              <p>
                The Identifier Alignment specifies how strictly DKIM and SPF
                policies are evaluated. Relaxed mode allows SPF Authenticated
                domains that share a common Organizational Domain with an
                email's header-from: domain to pass the DMARC check. Strict mode
                requires exact matching between the SPF domain and an email's
                header-from: domain.
              </p>
              <br />
              <div class="form-group">
                <a-row>
                  <a-col :span="12">
                    <div class="form-group">
                      <p class="form-label">DKIM Alignment</p>
                      <a-config-provider :theme="getSpecialTheme()">
                        <a-radio-group
                          v-model:value="dmarcData.dkim_identifier"
                        >
                          <a-radio value="r" :style="radioStyle">
                            Relaxed (default)
                          </a-radio>
                          <a-radio value="s" :style="radioStyle"
                            >Strict</a-radio
                          >
                        </a-radio-group>
                      </a-config-provider>
                    </div>
                  </a-col>
                  <a-col :span="12">
                    <div class="form-group">
                      <p class="form-label">SPF Alignment</p>
                      <a-config-provider :theme="getSpecialTheme()">
                        <a-radio-group v-model:value="dmarcData.spf_identifier">
                          <a-radio value="r" :style="radioStyle">
                            Relaxed (default)
                          </a-radio>
                          <a-radio value="s" :style="radioStyle"
                            >Strict</a-radio
                          >
                        </a-radio-group>
                      </a-config-provider>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </template>
            <!-- Subdomain Policy -->
            <template v-else-if="current == 3">
              <h4>Do you want a different policy for subdomains?</h4>
              <p>
                Selecting a policy It will only apply to subdomains of the
                domain queried and not to the domain itself.
              </p>
              <div class="form-group">
                <p class="form-label">Select subdomain policy</p>
                <a-config-provider :theme="getSpecialTheme()">
                  <a-radio-group v-model:value="dmarcData.subdomain_policy">
                    <a-radio value="none" :style="radioStyle">None</a-radio>
                    <a-radio value="quarantine" :style="radioStyle">
                      Quarantine
                    </a-radio>
                    <a-radio value="reject" :style="radioStyle">Reject</a-radio>
                  </a-radio-group>
                </a-config-provider>
              </div>
            </template>
            <!-- DMARC Record -->
            <template v-else-if="current == 4">
              <h4>Your DMARC Record Value is</h4>
              <div class="dmarc-record-value">
                <a-config-provider :theme="getSpecialTheme()">
                  <a-alert>
                    <template #description>
                      <div style="color: black">{{ dmarcRecord }}</div>
                      <a-tooltip>
                        <template #title>Copy to clipboard</template>
                        <a-button type="primary" @click="handleCopyDmarcRecord">
                          <template #icon>
                            <img src="@/assets/img/copy.svg" />
                          </template>
                        </a-button>
                      </a-tooltip>
                    </template>
                  </a-alert>
                </a-config-provider>
              </div>

              <h4>Add or update your record</h4>
              <ul>
                <li>Sign in to the management console for your domain host.</li>
                <li>
                  Add a DNS TXT record, or modify an existing record, by
                  entering your record in the TXT record for
                  <strong>_dmarc</strong>
                  <ol>
                    <li>
                      <strong>TXT record name:</strong> In the first field,
                      under the DNS Host name, enter:
                      <strong>{{ `_dmarc.${dmarcData.domain}` }}</strong>
                    </li>
                    <li>
                      <strong>TXT record value:</strong> In the second field,
                      enter the text for your DMARC record above.
                    </li>
                  </ol>
                </li>
                <li>
                  Once you add the record, you can execute a check to make sure
                  the DMARC Record is already registered.
                </li>
              </ul>
              <br />
              <br />
              <div class="flex items-center">
                <a-config-provider :theme="getSpecialTheme()">
                  <a-button
                    type="primary"
                    class="btn-check-dmarc"
                    @click="handleCheckDmarcRecord"
                  >
                    Check DMARC Record
                  </a-button>
                </a-config-provider>

                <template v-if="validateData.success">
                  <a-alert
                    type="success"
                    message="Congrats, you set up the DNS record correctly!"
                    show-icon
                  >
                    <template #icon>
                      <img src="@/assets/img/check_circle.svg" />
                    </template>
                  </a-alert>
                </template>
                <template v-if="validateData.fail">
                  <a-alert
                    type="error"
                    message="The DMARC Record is still not present in your DNS Records."
                    show-icon
                  >
                    <template #icon>
                      <img src="@/assets/img/warning.svg" />
                    </template>
                  </a-alert>
                </template>
              </div>
              <br />
              <br />
            </template>
          </a-layout-content>
          <a-layout-footer>
            <a-config-provider :theme="getSpecialTheme()">
              <a-button v-if="current != 4" type="primary" @click="handleNext">
                Next
              </a-button>
              <a-button
                v-else-if="current == 4"
                type="primary"
                @click="handleFinish"
              >
                Finish
              </a-button>
              <a-button
                v-if="current != 0 && !validateData.success"
                class="mr-10"
                type="primary"
                @click="handlePrevious"
              >
                Previous
              </a-button>
            </a-config-provider>
          </a-layout-footer>
        </a-layout>
      </a-layout>
    </a-spin>
  </a-modal>
</template>

<script>
import { message } from 'ant-design-vue';
import { defineComponent, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import api from '@/api';
import { copyToClipboard } from '@/utils/util';

export default defineComponent({
  name: 'DMARC-Record-Generator',
  props: ['open'],
  emits: ['close'],
  setup(props, { emit }) {
    const router = useRouter();

    const dmarcData = ref({
      domain: '',
      policy: 'none',
      dkim_identifier: 'r',
      spf_identifier: 'r',
      subdomain_policy: 'none',
    });
    const dmarcRecord = ref('');
    const validateData = ref({
      success: false,
      fail: false,
    });
    const radioStyle = reactive({
      display: 'flex',
      height: '30px',
      lineHeight: '30px',
    });
    const current = ref(0);
    const steps = [
      {
        title: 'STEP 1:',
        description: 'Enter Domain',
      },
      {
        title: 'STEP 2:',
        description: 'DMARC Policy',
      },
      {
        title: 'STEP 3:',
        description: 'Identifier Alignment',
      },
      {
        title: 'STEP 4:',
        description: 'Subdomain Policy',
      },
      {
        title: 'STEP 5:',
        description: 'DMARC Record',
      },
    ];
    const items = steps.map((item) => ({
      key: item.title,
      title: item.title,
      description: item.description,
    }));
    const loading = ref(false);

    const handlePrevious = () => {
      current.value--;
    };

    const handleNext = () => {
      switch (current.value) {
        case 0:
          // eslint-disable-next-line no-case-declarations
          const domainRegex =
            /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;
          if (!domainRegex.test(dmarcData.value.domain)) {
            message.error('Invalid domain');
            break;
          }
          current.value++;
          break;
        case 3:
          loading.value = true;

          api.DmarcApi.generateDmarcDomain(dmarcData.value)
            .then((res) => {
              current.value++;
              dmarcRecord.value = res.data;
            })
            .finally(() => {
              loading.value = false;
            });
          break;
        default:
          current.value++;
          break;
      }
    };

    const handleFinish = () => {
      dmarcData.value = {
        domain: '',
        policy: 'none',
        dkim_identifier: 'r',
        spf_identifier: 'r',
        subdomain_policy: 'none',
      };

      emit('close');
      router.go();
    };

    const handleCheckDmarcRecord = () => {
      loading.value = true;

      api.DmarcApi.validateDmarcRecord(
        dmarcRecord.value,
        dmarcData.value.domain
      )
        .then((data) => {
          if (data) validateData.value.success = true;
          else validateData.value.fail = true;
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const handleCopyDmarcRecord = () => {
      copyToClipboard(dmarcRecord.value);
      return;
    };

    watch(
      () => props.open,
      (open) => {
        if (!open) return;

        current.value = 0;
      }
    );

    return {
      steps,
      dmarcData,
      dmarcRecord,
      radioStyle,
      validateData,
      current,
      items,
      loading,
      handleNext,
      handlePrevious,
      handleFinish,
      handleCheckDmarcRecord,
      handleCopyDmarcRecord,
    };
  },
});
</script>

<style lang="less" scoped>
@import './RecordGenerator.less';
</style>

<style lang="less">
.record-generator {
  .ant-modal-body {
    display: initial !important;
  }

  .form-group {
    position: absolute;
    top: 40%;
    width: 50%;
  }

  .ant-alert-info {
    background-color: #d2f0f3;
    border: none;
  }

  .ant-alert-description {
    display: flex;
    gap: 25px;
    align-items: center;
  }

  .ant-alert {
    align-self: center;
    color: white;
    border: 1px;
    border-radius: 8px;
  }

  .ant-alert-success {
    background-color: #2f858d;
    border: none;
  }

  .ant-alert-error {
    background-color: #ff6384;
    border: none;
  }

  .ant-alert-message {
    color: white;
  }

  .ant-alert-icon {
    filter: brightness(0) invert(1);
    width: 16px;
  }
}
</style>
