<template>
  <a-modal
    width="80%"
    centered
    :closable="false"
    :footer="null"
    @cancel="handleFinish"
    :open="open"
    wrap-class-name="header-steps-modal dmarc-setup-modal"
  >
    <a-spin size="large" :spinning="loading">
      <a-layout>
        <a-layout-sider>
          <a-config-provider :theme="getSpecialTheme()">
            <a-steps direction="vertical" :current="current" :items="items">
            </a-steps>
          </a-config-provider>
        </a-layout-sider>
        <a-layout>
          <a-layout-header>
            <a-config-provider :theme="getSpecialTheme()">
              <a-button @click="handleFinish" type="text">
                <i class="fa-solid fa-xmark" />
              </a-button>
            </a-config-provider>
          </a-layout-header>
          <a-layout-content class="main-content">
            <template v-if="current == 0">
              <span class="steps-label">
                Generate a DMARC record and save into your DNS records.
              </span>

              <br />

              <a-alert type="info" show-icon>
                <template #icon>
                  <img src="@/assets/img/bulb-icon.png" />
                </template>
                <template #description>
                  You can generate a DMARC record by clicking here. Then follow
                  the guide to include the TXT record into your DNS.
                </template>
              </a-alert>
            </template>
            <template v-else-if="current == 1">
              <span class="steps-label"> Include your domains </span>

              <br />

              <a-row :gutter="[16]">
                <a-col span="8">
                  <div class="form-group">
                    <p class="form-label">Domain</p>
                    <a-input v-model:value="newDomain" />
                  </div>
                </a-col>
                <a-col span="6">
                  <a-config-provider :theme="getSpecialTheme()">
                    <div style="margin-top: 25px">
                      <a-button type="primary" @click="handleAddDomain">
                        Add
                      </a-button>
                    </div>
                  </a-config-provider>
                </a-col>
              </a-row>

              <a-table
                :dataSource="dataSource"
                :columns="columns"
                :pagination="false"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.name == 'delete'">
                    <a-button type="link" @click="handleDeleteDomain(record)">
                      <img src="@/assets/img/delete.svg" />
                    </a-button>
                  </template>
                </template>
              </a-table>
            </template>
          </a-layout-content>
          <a-layout-footer>
            <a-config-provider :theme="getSpecialTheme()">
              <a-button
                v-if="current < items.length - 1"
                type="primary"
                @click="handleNext"
              >
                Next
              </a-button>
              <a-button
                v-if="current == items.length - 1"
                type="primary"
                @click="handleFinish"
              >
                Finish
              </a-button>
            </a-config-provider>
          </a-layout-footer>
        </a-layout>
      </a-layout>
    </a-spin>
  </a-modal>
</template>

<script>
import { message } from 'ant-design-vue';
import { defineComponent, ref, watch } from 'vue';

import api from '@/api';

const columns = [
  {
    title: 'Domains',
    dataIndex: 'domain_name',
    width: '90%',
  },
  {
    title: '',
    name: 'delete',
    align: 'center',
  },
];

export default defineComponent({
  props: ['open'],
  emits: ['close'],
  setup(props, { emit }) {
    const dataSource = ref([]);
    const newDomain = ref('');
    const current = ref(0);
    const steps = [
      {
        title: 'STEP 1:',
        description: 'Generate DMARC record',
      },
      {
        title: 'STEP 2:',
        description: 'Include Domains',
      },
    ];
    const items = steps.map((item) => ({
      key: item.title,
      title: item.title,
      description: item.description,
    }));
    const loading = ref(false);

    const getDmarcDomains = () => {
      loading.value = true;

      api.DmarcApi.getDmarcDomains()
        .then((data) => {
          dataSource.value = data;
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const handleAddDomain = () => {
      if (!newDomain.value) return;

      loading.value = true;

      api.DmarcApi.saveDmarcDomain(newDomain.value)
        .then(() => {
          message.success(`Domain ${newDomain.value} already registered`);
          newDomain.value = '';
          getDmarcDomains();
        })
        .catch(() => {
          loading.value = false;
        });
    };

    const handleDeleteDomain = (record) => {
      loading.value = true;

      api.DmarcApi.deleteDmarcDomain(record.domain_id)
        .then(() => {
          message.success(`Domain ${record.domain_name} removed`);
          getDmarcDomains();
        })
        .catch(() => {
          loading.value = false;
        });
    };

    const handleNext = () => {
      current.value++;
    };

    const handleFinish = () => {
      emit('close');
    };

    watch(
      () => props.open,
      (open) => {
        if (!open) return;

        current.value = 0;
        getDmarcDomains();
      }
    );

    return {
      columns,
      dataSource,
      newDomain,
      current,
      items,
      loading,
      handleNext,
      handleFinish,
      handleAddDomain,
      handleDeleteDomain,
    };
  },
});
</script>

<style lang="less">
.dmarc-setup-modal {
  .ant-alert {
    color: initial;

    > img {
      filter: none;
    }
  }
}
</style>
