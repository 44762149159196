<template>
  <span>Include your domains</span>
  <br />

  <h4>Add custom domain</h4>

  <div class="add-domain-section">
    <a-row :gutter="[20, 20]">
      <a-col :span="6">
        <div class="form-group">
          <p class="form-label">Domain</p>
          <a-select :options="allDomainsOptions" v-model:value="newDomain" />
        </div>
      </a-col>
      <a-col :span="6">
        <a-config-provider :theme="getSpecialTheme()">
          <div style="margin-top: 25px">
            <a-button
              type="primary"
              :disabled="!newDomain"
              @click="handleSaveDomain"
            >
              Add
            </a-button>
          </div>
        </a-config-provider>
      </a-col>
    </a-row>
  </div>

  <a-table :columns="columns" :dataSource="dataSource" :pagination="false">
    <template #bodyCell="{ column, record }">
      <template v-if="column.name == 'action'">
        <a-button type="link" @click="handleDeleteDomain(record)">
          <template #icon>
            <img src="@/assets/img/delete.svg" />
          </template>
        </a-button>
      </template>
    </template>
  </a-table>
</template>

<script>
import { message } from 'ant-design-vue';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import api from '@/api';
import { STORE_EVENT } from '@/store';

const columns = [
  {
    title: 'Domain',
    dataIndex: 'domain_name',
  },
  {
    title: '',
    name: 'action',
  },
];

export default defineComponent({
  name: 'Setup-3',
  setup() {
    const store = useStore();
    const dataSource = ref([]);
    const allDomains = ref([]);
    const newDomain = ref(null);

    const getGptDomains = () => {
      store.dispatch(STORE_EVENT.START_LOADING_PAGE);
      api.SndsApi.getGptDomains()
        .then((data) => {
          dataSource.value = data;
        })
        .finally(() => {
          store.dispatch(STORE_EVENT.STOP_LOADING_PAGE);
        });
    };

    const handleDeleteDomain = (record) => {
      store.dispatch(STORE_EVENT.START_LOADING_PAGE);
      api.SndsApi.deleteGptDomain(record.gpt_domain_id)
        .then(() => {
          message.success(`Domain ${record.domain_name} removed`);
          getGptDomains();
        })
        .catch(() => {
          store.dispatch(STORE_EVENT.STOP_LOADING_PAGE);
        });
    };

    const handleSaveDomain = () => {
      store.dispatch(STORE_EVENT.START_LOADING_PAGE);
      api.SndsApi.saveGptDomain(newDomain.value)
        .then((response) => {
          if (response && response.error) {
            message.error('Domain verification failed.');
          } else {
            message.success(`Domain ${newDomain.value} verified with GPT`);
          }
          newDomain.value = null;
          getGptDomains();
        })
        .catch(() => {
          store.dispatch(STORE_EVENT.STOP_LOADING_PAGE);
        });
    };

    const allDomainsOptions = computed(() =>
      allDomains.value.map((item) => ({
        label: item.sendingServerIp,
        value: item.sendingServerIp,
      }))
    );

    onMounted(() => {
      store.dispatch(STORE_EVENT.START_LOADING_PAGE);
      Promise.all([
        api.ReputationApi.getAllDomainOrIp(),
        api.SndsApi.getGptDomains(),
      ])
        .then((data) => {
          allDomains.value = data[0].data;
          dataSource.value = data[1];
        })
        .finally(() => {
          store.dispatch(STORE_EVENT.STOP_LOADING_PAGE);
        });
    });

    return {
      columns,
      dataSource,
      newDomain,
      allDomainsOptions,
      handleDeleteDomain,
      handleSaveDomain,
    };
  },
});
</script>
