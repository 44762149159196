<template>
  <a-modal
    :open="openDetails.open"
    :title="modalTitle"
    width="80%"
    @cancel="handleCloseModal"
    wrap-class-name="alert-details-modal"
  >
    <a-spin size="large" :spinning="loading">
      <template v-if="openDetails.type === DETAILS_TYPE.CREATE">
        <a-row :gutter="[16]">
          <a-col span="8">
            <div class="form-group">
              <p class="form-label">Event Type</p>
              <a-select
                placeholder="Select an option"
                size="large"
                :options="allEventTypesOptions"
                v-model:value="alertDetails.eventTypeCode"
              />
            </div>
          </a-col>
        </a-row>
      </template>

      <hr />

      <a-row
        v-if="alertDetails.eventTypeCode === 'DELIVERY_RATE'"
        :gutter="[16]"
      >
        <a-col span="8">
          <div class="form-group">
            <p class="form-label">
              Delivery Metric
              <span style="color: red">*</span>
            </p>
            <a-select
              class="w-full"
              size="large"
              v-model:value="alertDetails.deliveryRateCriteria.deliveryType"
              :options="[
                { value: 'INBOX', label: 'Inbox' },
                { value: 'SPAM', label: 'Spam' },
                { value: 'MISSING', label: 'Missing' },
              ]"
            />
          </div>
        </a-col>
        <a-col span="8">
          <div class="form-group">
            <p class="form-label">
              Comparison
              <span style="color: red">*</span>
            </p>
            <a-select
              class="w-full"
              size="large"
              v-model:value="alertDetails.deliveryRateCriteria.operation"
              :options="[
                { value: '>', label: 'Greater than (>)' },
                { value: '<', label: 'Less than (<)' },
                { value: '>=', label: 'Greater than or equals (≥)' },
                { value: '<=', label: 'Less than or equals (≤)' },
              ]"
            />
          </div>
        </a-col>
        <a-col span="8">
          <div class="form-group">
            <p class="form-label">
              Rate %
              <span style="color: red">*</span>
            </p>
            <a-input-number
              class="w-full"
              size="large"
              v-model:value="alertDetails.deliveryRateCriteria.rate"
            />
          </div>
        </a-col>
        <a-col span="8">
          <div class="form-group">
            <p class="form-label">ISPs</p>
            <a-select
              class="w-full"
              size="large"
              mode="multiple"
              v-model:value="alertDetails.isp_id_list"
              :options="ispsOptions"
            />
          </div>
        </a-col>
        <a-col span="8">
          <div class="form-group">
            <p class="form-label">Perform test after...</p>
            <a-select
              class="w-full"
              size="large"
              v-model:value="
                alertDetails.deliveryRateCriteria.minCollectionTime
              "
              :options="[
                { value: 'PT15M', label: '15 minutes (Recommended)' },
                { value: 'PT30M', label: '30 minutes' },
                { value: 'PT45M', label: '45 minutes' },
                { value: 'PT60M', label: '60 minutes' },
                { value: 'PT90M', label: '90 minutes' },
                { value: 'PT120M', label: '120 minutes' },
              ]"
            />
          </div>
        </a-col>
        <a-col span="8">
          <div class="form-group">
            <p class="form-label">Perform test when...</p>
            <div class="w-full flex items-center">
              <a-checkbox
                class="min-collection-percent"
                v-model:checked="allowedMinCollectionPercent"
              />
              <a-select
                class="flex-1"
                size="large"
                :disabled="!allowedMinCollectionPercent"
                v-model:value="
                  alertDetails.deliveryRateCriteria.minCollectionPercent
                "
                :options="[
                  { value: 25, label: '25% of seeds are received' },
                  { value: 50, label: '50% of seeds are received' },
                  { value: 75, label: '75% of seeds are received' },
                  { value: 100, label: '100% of seeds are received' },
                ]"
              />
            </div>
          </div>
        </a-col>
      </a-row>

      <br />

      <p class="form-label">Select an endpoint</p>

      <a-row :gutter="[16]">
        <a-col span="8">
          <div class="form-group">
            <p class="form-label">Alert type</p>
            <div class="w-full flex items-center">
              <a-select
                class="w-full"
                size="large"
                :options="allAlertTypesOptions"
                v-model:value="alertDetails.alertType"
              />
            </div>
          </div>
        </a-col>
        <a-col span="8">
          <template v-if="alertDetails.alertType === 'webhook'">
            <div class="form-group">
              <p class="form-label">Webhook</p>
              <a-input
                class="w-full"
                size="large"
                v-model:value="alertDetails.webhookUrl"
              />
            </div>
          </template>
          <template v-else-if="alertDetails.alertType === 'sms'">
            <div class="form-group">
              <p class="form-label">Phone Number</p>
              <a-input
                class="w-full"
                size="large"
                v-model:value="alertDetails.sms"
              />
            </div>
          </template>
          <template v-else-if="alertDetails.alertType === 'email'">
            <div class="form-group">
              <p class="form-label">Email Address</p>
              <a-input
                class="w-full"
                size="large"
                v-model:value="alertDetails.email"
              />
            </div>
          </template>
        </a-col>
      </a-row>
      <a-row :gutter="[16]">
        <a-col span="8"></a-col>
        <a-col span="8">
          <template v-if="alertDetails.alertType === 'webhook'">
            <div class="form-group">
              <p class="form-label">User</p>
              <a-input
                class="w-full"
                size="large"
                v-model:value="alertDetails.webhookUrlUser"
              />
            </div>
          </template>
        </a-col>
      </a-row>
      <a-row :gutter="[16]">
        <a-col span="8"></a-col>
        <a-col span="8">
          <template v-if="alertDetails.alertType === 'webhook'">
            <div class="form-group">
              <p class="form-label">Password</p>
              <a-input
                class="w-full"
                size="large"
                v-model:value="alertDetails.webhookUrlPassword"
              />
            </div>
          </template>
        </a-col>
      </a-row>
    </a-spin>

    <template #footer>
      <a-button :disabled="loading" @click="handleCloseModal">
        Cancel
      </a-button>
      <a-config-provider :theme="getSpecialTheme()">
        <a-button type="primary" :loading="loading" @click="handleAddHandler">
          Save
        </a-button>
      </a-config-provider>
    </template>
  </a-modal>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import api from '@/api';
import { DETAILS_TYPE } from '@/utils/util';

const columns = [
  { title: 'ALERT TYPE', name: 'alert-type', width: '30%' },
  { title: 'EMAIL/PHONE NUMBER' },
];

export default defineComponent({
  name: 'AlertDetailsModal',
  props: ['openDetails', 'allAlertTypes', 'allEventTypes', 'isps'],
  emits: ['close', 'update'],
  setup(props, { emit }) {
    const router = useRouter();

    const alertDetails = ref({
      eventTypeCode: 'DNSBL.Hit',
      alertType: 'webhook',
      webhookUrl: null,
      webhookUrlUser: null,
      webhookUrlPassword: null,
      sms: null,
      email: null,
      deliveryRateCriteria: {
        operation: null,
        rate: null,
        minCollectionTime: 'PT15M',
        minCollectionPercent: null,
        deliveryType: null,
      },
      isp_id_list: [],
    });
    const allowedMinCollectionPercent = ref(false);
    const loading = ref(false);

    watch(
      () => props.openDetails,
      (openDetails) => {
        if (openDetails.open) {
          if (openDetails.type === DETAILS_TYPE.CREATE) {
            alertDetails.value = {
              eventTypeCode: 'DNSBL.Hit',
              alertType: 'webhook',
              webhookUrl: null,
              webhookUrlUser: null,
              webhookUrlPassword: null,
              sms: null,
              email: null,
              deliveryRateCriteria: {
                operation: null,
                rate: null,
                minCollectionTime: 'PT15M',
                minCollectionPercent: null,
                deliveryType: null,
              },
              isp_id_list: [],
            };
          } else {
            alertDetails.value = { ...openDetails.data };
            const { deliveryRateCriteria } = alertDetails.value;

            if (alertDetails.value.isp_id_list == null) {
              alertDetails.value.isp_id_list = [];
            }
            if (
              deliveryRateCriteria &&
              deliveryRateCriteria.minCollectionPercent
            )
              allowedMinCollectionPercent.value = true;
            else allowedMinCollectionPercent.value = false;
          }
        }
      }
    );

    const ispsOptions = computed(() =>
      props.isps.map((item) => ({ label: item.isp_name, value: item.isp_id }))
    );

    const handleCloseModal = () => {
      if (loading.value) return;

      emit('close');
    };

    const handleAddHandler = () => {
      const { allEventTypes, allAlertTypes, openDetails } = props;
      const {
        endpointId,
        handlerId,
        eventTypeCode,
        alertType,
        webhookUrl,
        webhookUrlPassword,
        webhookUrlUser,
        sms,
        email,
        deliveryRateCriteria,
        isp_id_list,
      } = alertDetails.value;

      const data = {
        eventTypeId: allEventTypes.find(
          (item) => item.eventTypeCode === eventTypeCode
        ).eventTypeId,
        alertTypeId: allAlertTypes.find(
          (item) => item.alertTypeCode === alertType
        ).alertTypeId,
      };

      if (alertType === 'webhook') {
        data.webhookUrl = webhookUrl;
        data.webhookUrlUser = webhookUrlUser;
        data.webhookUrlPassword = webhookUrlPassword;
      } else if (alertType === 'sms') data.sms = sms;
      else if (alertType === 'email') data.email = email;

      if (eventTypeCode !== 'DNSBL.Hit') {
        const {
          deliveryType,
          operation,
          rate,
          minCollectionTime,
          minCollectionPercent,
        } = deliveryRateCriteria;

        data.isp_id_list = isp_id_list;
        data.deliveryRateCriteria = {
          deliveryType,
          operation,
          rate,
          minCollectionTime,
        };

        if (allowedMinCollectionPercent.value) {
          data.deliveryRateCriteria.minCollectionPercent = minCollectionPercent;
        }
      }

      loading.value = true;

      if (openDetails.type === DETAILS_TYPE.CREATE)
        api.AlertApi.addHandler(data)
          .then(() => {
            emit('update');
          })
          .finally(() => {
            loading.value = false;
            router.go();
          });
      else {
        data.endpointId = endpointId;
        data.handlerId = handlerId;

        if (eventTypeCode !== 'DNSBL.Hit') {
          data.deliveryRateCriteria.criteriaId =
            deliveryRateCriteria.criteriaId;
        }

        api.AlertApi.updateAlert(data)
          .then(() => {
            emit('update');
          })
          .finally(() => {
            loading.value = false;
            router.go();
          });
      }
    };

    const allAlertTypesOptions = computed(() =>
      props.allAlertTypes.map((item) => ({
        label: item.alertTypeCode,
        value: item.alertTypeCode,
      }))
    );

    const allEventTypesOptions = computed(() =>
      props.allEventTypes.map((item) => ({
        label:
          item.eventTypeDesc === 'DNSBL Hits'
            ? 'Domain Name Server Block List Hit'
            : item.eventTypeDesc,
        value: item.eventTypeCode,
      }))
    );

    const modalTitle = computed(() =>
      props.openDetails.type === DETAILS_TYPE.CREATE
        ? 'Add alert'
        : 'Edit alert'
    );

    return {
      modalTitle,
      allAlertTypesOptions,
      allEventTypesOptions,
      alertDetails,
      columns,
      allowedMinCollectionPercent,
      DETAILS_TYPE,
      loading,
      ispsOptions,
      handleCloseModal,
      handleAddHandler,
    };
  },
});
</script>

<style lang="less">
.alert-details-modal {
  .form-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }

  .form-label {
    font-weight: 700;
    margin-bottom: 5px;
  }

  .min-collection-percent {
    margin-right: 7px;
  }

  .ant-modal {
    width: 80%;
  }

  .modal-content {
    gap: 8px;

    > div {
      display: flex;
      flex-direction: column;
      flex: 0 0 33%;
      max-width: 33%;
    }
  }

  .ant-modal-body {
    width: 70%;
    align-self: center;
  }
}
</style>
